// Popups module scripts
function initPopups() {
    console.warn('init popup');
    const $popups = $('.js-popup');
    $popups.each(function () {
        initPopup($(this));
    });
}

function initPopup($popup) {
    const $timer = $popup.find('.js-popup-timer');
    const delay = $popup.data('delay') || 0; // in seconds
    const delayClose = $popup.data('delay-close') || 0; // in seconds

    setTimeout(function () {
        showClose($timer, delayClose, $popup);
    }, delay * 1000);

    // Show popup after delay
    setTimeout(function () {
        showPopup($popup);
    }, delay * 1000);
}

function initClose($popup) {
    const $btnClose = $popup.find('.js-popup-close');

    // Events
    $btnClose.on('click', function () {
        closePopup($popup);
    });
}

function showClose($btn, delay, $popup) {
    var seconds = delay;

    var x = setInterval(function () {
        seconds = seconds - 1;
        $btn.text(seconds);

        if (seconds < 1) {
            clearInterval(x);
            $btn.text('').addClass('icon').removeClass('js-popup-timer');
            initClose($popup);
            clickButton($popup);
        }
    }, 1000);
}

function closePopup($popup) {
    $popup.fadeOut();
    setPopupCookie($popup);
}

function showPopup($popup) {
    if (checkPopupCookie($popup) === false) {
        $popup.fadeIn();
    }
}

function setPopupCookie($popup) {
    const id = $popup.data('id');
    const dataInterval = $popup.data('interval');
    if (dataInterval === 0) return;
    const interval = dataInterval || 7;

    const now = new Date();
    const time = now.getTime();
    const expireTime = time + interval * 24 * 3600 * 1000;

    if (!id) {
        console.log('Popup ID is necessary to set cookie.');
        return;
    }

    document.cookie = `popup_${id}=shown;expires=${new Date(
        expireTime
    ).toUTCString()};path=/`;
}

function checkPopupCookie($popup) {
    const id = $popup.data('id');
    const popupCookie = getCookie(`popup_${id}`);

    return popupCookie?.length > 0 ? true : false;
}

function getCookie(name) {
    var value = '; ' + document.cookie;
    var parts = value.split('; ' + name + '=');
    if (parts.length === 2) return parts.pop().split(';').shift();
}

function clickButton($popup) {
    var clickButton = $popup.find('.popup__button a.button');
    console.warn(clickButton);

    clickButton.on('click', function (event) {
        event.preventDefault();

        var targetUrl = $(this).attr('href');

        setPopupCookie($popup);

        setTimeout(function () {
            window.location.href = targetUrl;
        }, 300);
    });
}

export { initPopups };
