function popupCounter() {
    console.warn('Ładowanie licznika zamykania popup');
    $('.popup-close').on('click', function () {
        const popupId = $(this).data('popup-id');

        if (!popupId) {
            console.warn('Popup ID is not set');
            return;
        }

        $.ajax({
            url: ajax.url,
            type: 'POST',
            data: {
                action: 'count_popup_close',
                popup_id: popupId,
                nonce: popupCounter.nonce,
            },
            success: function (response) {
                if (response.success) {
                    console.log(
                        'Popup closed. New count: ' + response.data.new_count
                    );
                } else {
                    console.log('Error:', response.data.message);
                }
            },
            error: function () {
                console.log('AJAX request failed.');
            },
        });
    });
}

export { popupCounter };
